import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMatchupScores } from "../../hooks/data/db";
import { Player } from "../../types/db";

import styles from "./style.module.scss";

type GetURL = (ids: number[]) => string;

function OpponentRow({
  matches,
  games,
  opponent,
  getOpponentURL,
  flipped,
}: {
  matches: [number, number];
  games: [number, number];
  opponent: Player[];
  getOpponentURL: GetURL;
  flipped?: boolean;
}) {
  const location = useLocation();

  const ratios = [matches, games].map(([a, b]) => {
    const s = a + b;
    return s ? a / s : 0.5;
  });
  const percents = ratios.map((x) => `${(x * 100).toFixed(0)}%`);
  const pathname = getOpponentURL(opponent.map((p) => p.smashId));

  return (
    <tr
      className={classNames({
        [flipped ? styles.rightW : styles.leftW]: ratios[0] > 0.5,
      })}
    >
      <td className={styles.left}>{matches[0]}</td>
      <td className={styles.dash}>—</td>
      <td>{matches[1]}</td>
      <td className={styles.percent}>{percents[0]}</td>
      <td className={styles.opponent}>
        <Link to={{ pathname, search: location.search }}>
          {opponent.map((p) => p.name).join(" / ")}
        </Link>
      </td>
      <td className={styles.left}>{games[0]}</td>
      <td className={styles.dash}>—</td>
      <td>{games[1]}</td>
      <td className={styles.percent}>{percents[1]}</td>
    </tr>
  );
}

export function MatchupOpponents({
  entrant1SmashIds,
  entrant2SmashIds,
  gameMode,
  isOfficial,
  getLeftURL,
  getRightURL,
}: {
  entrant1SmashIds: (number | undefined)[];
  entrant2SmashIds: (number | undefined)[];
  gameMode: number;
  isOfficial?: boolean;
  getLeftURL: GetURL;
  getRightURL: GetURL;
}) {
  const [tab, setTab] = useState(0);

  const { data, isValidating } = useMatchupScores(
    [entrant1SmashIds, entrant2SmashIds][tab],
    null,
    gameMode,
    isOfficial
  );

  const matchups = data?.matchups || [];

  const hasRight = entrant2SmashIds.filter((x) => x).length > 0;

  useEffect(() => {
    if (!hasRight) setTab(0);
  }, [hasRight]);

  return (
    <div className={styles.container}>
      {hasRight && (
        <header>
          <div
            className={classNames({ [styles.active]: tab === 0 })}
            onClick={() => setTab(0)}
          >
            Left
          </div>
          <div
            className={classNames({ [styles.active]: tab === 1 })}
            onClick={() => setTab(1)}
          >
            Right
          </div>
        </header>
      )}

      <main>
        {matchups.length ? (
          <>
            <table className={styles.opponents}>
              <thead>
                <tr>
                  <th colSpan={4}>Matches</th>
                  <th className={styles.opponent}>Opponent</th>
                  <th colSpan={4}>Games</th>
                </tr>
              </thead>
              <tbody>
                {matchups.map((data, i) => (
                  <OpponentRow
                    {...data}
                    key={i}
                    getOpponentURL={tab === 0 ? getRightURL : getLeftURL}
                    flipped={tab === 1}
                  />
                ))}
              </tbody>
            </table>
          </>
        ) : isValidating ? null : (
          <div style={{ opacity: 0.5, fontWeight: 500 }}>
            Couldn't find any opponents.
          </div>
        )}
      </main>
    </div>
  );
}
