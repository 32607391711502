import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { EventSelector } from "../components/event-selector";
import { InputRow, Label, Select } from "../components/inputs";
import { smashFetcher } from "../hooks/data/api";
import { useSGGEvent } from "../hooks/data/smashgg";

import styles from "./config.module.scss";

export type Config = {
  isOfficial: boolean;
  setIsOfficial: (b: boolean | undefined) => void;
  eventSlug: string | undefined;
  setEventSlug: (s: string | undefined) => void;
  year: number | undefined;
  setYear: (y: number | undefined) => void;
};

const thisYear = new Date().getFullYear();
const firstYear = 2017;

const ConfigContext = React.createContext<Config>({
  isOfficial: false,
  year: 0,
  eventSlug: undefined,
  setIsOfficial() {},
  setEventSlug() {},
  setYear() {},
});

export const ConfigProvider = ConfigContext.Provider;
export const useConfig = () => useContext(ConfigContext);

export function useConfigParams(
  gameMode: number,
  setLink: (l: string) => void
): Config {
  const params = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const [isOfficial, setIsOfficial] = useQueryParam(
    "official",
    withDefault(BooleanParam, false)
  );
  const [rawSlug, setRawSlug] = useQueryParam(
    "event",
    withDefault(StringParam, undefined)
  );
  const [year, setYear] = useQueryParam(
    "year",
    withDefault(NumberParam, thisYear)
  );

  useEffect(() => {
    if (isNaN(year)) return setYear(undefined);
    if (year < 2017) return setYear(2017);
    if (year > thisYear) return setYear(thisYear);
  }, [year, setYear]);

  // Convert a tournament slug to an event slug.
  let [_slug, _setSlug] = useState<string | undefined>();
  useEffect(() => {
    let cancelled = false;
    if (!rawSlug) return _setSlug(undefined);
    if (rawSlug.includes("/event/")) return _setSlug(rawSlug);

    smashFetcher(
      `query ($slug: String!)  {
        tournament(slug: $slug) {
          events {
            slug
          }
        }
      }`,
      { slug: rawSlug }
    ).then((res) => {
      if (cancelled) return;
      if (!res.tournament || !res.tournament.events!.length) {
        alert(`Couldn't find a tournament with slug "${rawSlug}"!`);
        return setRawSlug(undefined, "replace");
      }
      setRawSlug(res.tournament.events![0]?.slug, "replace");
    });
    return () => void (cancelled = true);
  }, [rawSlug, setRawSlug]);

  const evt = useSGGEvent(_slug);

  const [cleanSlug, setCleanSlug] = useState<string | undefined>();

  useEffect(() => {
    if (!_slug) setCleanSlug(undefined);
    if (evt.isValidating || !evt.data) return;
    const { event } = evt.data;

    if (!event) {
      alert(`Couldn't find an event with slug "${rawSlug}"!`);
      return setRawSlug(cleanSlug, "replace");
    }

    const teamSize = event.teamRosterSize?.minPlayers === 2 ? 2 : 1;
    if (teamSize === gameMode) {
      setCleanSlug(_slug);
    } else {
      // const target = ["singles", "doubles"][teamSize - 1];
      let pathname;
      if (teamSize === 2) {
        pathname =
          "/doubles/" + [params.p1, params.p2].filter((x) => x).join("/");
      } else {
        pathname =
          "/singles/" + [params.e1, params.e2].filter((x) => x).join("/");
      }

      navigate(
        {
          pathname,
          search: location.search,
        },
        { replace: true }
      );
    }
  }, [
    evt.data,
    evt.isValidating,
    gameMode,
    _slug,
    cleanSlug,
    rawSlug,
    setRawSlug,
    location.search,
    navigate,
    params.e1,
    params.e2,
    params.p1,
    params.p2,
  ]);

  useEffect(() => {
    const s = new URLSearchParams(location.search);
    if (cleanSlug) s.set("event", cleanSlug);
    else s.delete("event");
    setLink([location.pathname, s.toString()].filter(Boolean).join("?"));
  }, [location.pathname, location.search, cleanSlug, setLink]);

  return {
    isOfficial,
    setIsOfficial,
    eventSlug: cleanSlug,
    setEventSlug: setRawSlug,
    year,
    setYear,
  };
}

export function ConfigControls() {
  const { eventSlug, isOfficial, setEventSlug, setIsOfficial, setYear, year } =
    useConfig();

  return (
    <div className={styles.config}>
      <EventSelector slug={eventSlug} setSlug={setEventSlug} />
      <InputRow>
        <Label label="Events">
          <Select
            value={String(Number(isOfficial || false))}
            onChange={(e) =>
              setIsOfficial(e.target.value === "1" ? true : undefined)
            }
          >
            <option value="0">All</option>
            <option value="1">Official</option>
          </Select>
        </Label>
        <Label label="Year">
          <Select
            value={year || -1}
            onChange={(e) => {
              const y = parseInt(e.target.value);
              if (y === thisYear) return setYear(undefined);
              setYear(y);
            }}
          >
            {new Array(thisYear - firstYear + 1).fill(null).map((_, i) => (
              <option key={i} value={thisYear - i}>
                {thisYear - i}
              </option>
            ))}
          </Select>
        </Label>
      </InputRow>
    </div>
  );
}
