import { useEffect, useRef } from "react";
import classNames from "classnames";
import PlayerPlacements from "../../components/placements/player";
import PlayerInfo from "../../components/player-info";
import { usePlayerLegends } from "../../hooks/data/db";
import { useConfig } from "../config";

import styles from "../style.module.scss";
import { pStatic } from "../../constants";

export default function PlayerColumn({
  id,
  setId,
  flipped,
  disabled,
}: {
  id?: number;
  setId?: (i: number | undefined) => void;
  flipped?: boolean;
  disabled?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const config = useConfig();

  const legends = usePlayerLegends(id, 1, config.isOfficial, config.year);

  useEffect(() => {
    ref.current && (ref.current.scrollTop = 0);
  }, [id]);

  return (
    <div
      ref={ref}
      className={classNames(styles.column, {
        [styles.flipped]: flipped,
        [styles.disabled]: disabled,
      })}
    >
      <PlayerInfo
        gameMode={1}
        id={id}
        setIds={setId ? (ids) => setId(ids[0]) : undefined}
        flipped={flipped}
        eventSlug={config.eventSlug}
        disabled={disabled}
      />

      <section className={styles.legends}>
        {legends.data?.legends?.map((legend, i) => (
          <figure key={i} title={legend.name}>
            <img
              src={pStatic(`/heads/${legend.name}.png`)}
              alt={`${legend.name} (${legend.count})`}
            />
            <figcaption>
              <span>{legend.count}</span>
            </figcaption>
          </figure>
        ))}
        {new Array(5 - (legends.data?.legends?.length ?? 0))
          .fill(null)
          .map((_, i) => (
            <figure key={i}>
              <div style={{ height: 84, opacity: 0 }} />
              <figcaption style={{ opacity: 0 }}>
                <span>0</span>
              </figcaption>
            </figure>
          ))}
      </section>

      <PlayerPlacements
        entrantSmashIds={id ? [id] : []}
        gameMode={1}
        getOpponentURL={(opp) => {
          const ids = [id, opp[0]];
          flipped && ids.reverse();
          return "/singles/" + ids.join("/");
        }}
        isOfficial={config.isOfficial}
        flipped={flipped}
      />
    </div>
  );
}
