import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Select, Label, InputRow } from "../../components/inputs";
import useGradientBG from "../../hooks/use-gradient-bg";
import styles from "./style.module.scss";
import Wrapper from "./wrapper";

const streams = new Map(
  (
    (process.env.REACT_APP_LIVE_STREAMS || "")
      .split(",")
      .filter((x) => x)
      .map((x) => x.split("::")) as [string, string][]
  ).map(([label, url]) => [label.toLowerCase(), { label, url }])
);

if (process.env.NODE_ENV !== "production") {
  streams.set("local", { label: "Local", url: "http://localhost:55213" });
}
const route = (stream: string) => `/live/${stream}`;

export default function LiveStatsPage({
  setLink,
}: {
  setLink: (l: string) => void;
}) {
  const navigate = useNavigate();
  const params = useParams();
  const source = streams.get((params.source || "").toLowerCase());
  const [view, setView] = useState<string>("auto");
  const [colors, setColors] = useState<any>({});
  useEffect(() => {
    fetch("https://static.brawltools.com/live-stats/v2/75346877/config.json")
      .then((res) => res.json())
      .then((r) => {
        setColors({
          "--left": r.left,
          "--right": r.right,
        });
      });
  }, []);

  const location = useLocation();
  useEffect(() => {
    setLink(location.pathname);
  }, [location.pathname, setLink]);
  // useGradientBG();

  if (!source)
    return <Navigate to={route(Array.from(streams)[0][0])} replace />;

  return (
    <div className={styles.live} style={{ ...colors }}>
      <InputRow>
        <Label label="Source">
          <Select
            onChange={(e) => navigate(route(e.target.value))}
            value={params.source}
          >
            {Array.from(streams).map(([slug, { label }]) => (
              <option key={slug} value={slug}>
                {label}
              </option>
            ))}
          </Select>
        </Label>

        <Label label="View">
          <Select value={view} onChange={(e) => setView(e.target.value)}>
            <option value="auto">Auto</option>
            <option value="pre">Pre-Game</option>
            <option value="post">Post-Game</option>
          </Select>
        </Label>
      </InputRow>
      <Wrapper url={source.url} view={view} />
    </div>
  );
}
