import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import useDebounce from "../../hooks/use-debounce";
import React from "react";
import { useSearchResults } from "../../hooks/data/api";
import classNames from "classnames";

const PlayerSelector = React.forwardRef<
  HTMLInputElement,
  {
    eventSlug: string | undefined;
    disabled?: boolean;
    flipped?: boolean;
    gameMode: number;
    onBlur: () => void;
    onSelect: (ids: (number | undefined)[]) => void;
  }
>(({ eventSlug, disabled, flipped, gameMode, onBlur, onSelect }, ref) => {
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);

  const debouncedQuery = useDebounce(query, 200);
  const results = useSearchResults(debouncedQuery, eventSlug, gameMode);

  useEffect(() => setSelected(0), [results.data]);

  const selectActive = () => {
    if (!results.data) return;
    onSelect(results.data[selected]?.smashIds || []);
    onBlur();
  };

  return (
    <div className={classNames({ [styles.flipped]: flipped })}>
      <input
        disabled={disabled}
        ref={ref}
        type="text"
        placeholder="Player Name"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className={styles.search}
        onBlur={() => {
          setQuery("");
          setOpen(false);
          onBlur();
        }}
        onFocus={() => {
          setOpen(true);
        }}
        onKeyDown={(e) => {
          const l = results.data?.length || 1;
          if (e.key === "Escape") {
            (e.target as any).blur();
            e.preventDefault();
          }
          if (e.key === "ArrowDown") {
            setSelected((selected + 1) % l);
            e.preventDefault();
          }
          if (e.key === "ArrowUp") {
            setSelected((selected === 0 ? l : selected) - 1);
            e.preventDefault();
          }
          if (e.key === "Enter" && results.data?.length) {
            selectActive();
            e.preventDefault();
          }
        }}
      />
      <ul
        className={classNames(styles.results, {
          [styles.show]: open && !!results.data?.length,
        })}
        style={{ height: open ? (results.data?.length || 0) * 48 : 0 }}
      >
        {results.data?.map((res, i) => (
          <li
            key={i}
            onMouseEnter={() => setSelected(i)}
            onMouseDown={selectActive}
            className={classNames({ [styles.selected]: selected === i })}
          >
            <span>{res.name}</span>
            <span className={styles.extra}>{res.extra || ""}</span>
          </li>
        ))}
      </ul>
    </div>
  );
});
PlayerSelector.displayName = "PlayerSelector";

export default PlayerSelector;
