import classNames from "classnames";
import { Coins, Gamepad2, Globe, Twitch, Twitter } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useDBPlayer, usePlayerPR } from "../../hooks/data/db";
import { useSGGPlayer } from "../../hooks/data/smashgg";
import SmashGG from "../icons/smashgg";
import PlayerSelector from "../player-selector";
import styles from "./style.module.scss";

const moneyFmt = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export default function PlayerInfo({
  id,
  setIds,
  gameMode,
  flipped,
  eventSlug,
  disabled,
}: {
  id: number | undefined;
  setIds?: (id: (number | undefined)[], error?: boolean) => void;
  gameMode: number;
  flipped?: boolean;
  eventSlug: string | undefined;
  disabled?: boolean;
}) {
  const inpRef = useRef<HTMLInputElement>(null);

  const player = useDBPlayer(id);
  const sgg = useSGGPlayer(id);
  const pr = usePlayerPR(id, gameMode);

  // if (player.data?.error) setIds?.([], true);
  const p = sgg.data?.player;

  const classes = classNames({
    [styles.flipped]: flipped,
    [styles.disabled]: disabled,
  });

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (editing) inpRef.current?.focus();
  }, [editing]);

  const bhId = player.data?.player?.brawlhallaId;

  return (
    <>
      <header
        className={classNames(styles.header, classes, {
          [styles.static]: !setIds,
        })}
      >
        {setIds && (editing || !id) ? (
          <PlayerSelector
            ref={inpRef}
            eventSlug={eventSlug}
            disabled={disabled}
            flipped={flipped}
            gameMode={gameMode}
            onBlur={() => id && setEditing(false)}
            onSelect={(ids) => setIds?.(ids)}
          />
        ) : (
          <h1
            onClick={() => {
              setEditing(true);
              inpRef.current?.focus();
            }}
            className={classNames(styles.name, {
              [styles.preloader]: sgg.isValidating,
            })}
          >
            {sgg.isValidating ? (
              "Loading..."
            ) : (
              <>
                {p?.prefix && (
                  <span className={styles.sponsor}>{p?.prefix}</span>
                )}
                {p?.gamerTag}
              </>
            )}
          </h1>
        )}
        <div className="spacer" />
      </header>
      <section className={classNames(styles.social, classes)}>
        {bhId && (
          <a
            href={`http://corehalla.com/stats/player/${bhId}`}
            target="_blank"
            className="default"
            title="View on Corehalla"
            rel="noreferrer"
          >
            <Gamepad2 />
          </a>
        )}
        {sgg.data?.player?.user?.discriminator && (
          <a
            href={`https://smash.gg/user/${sgg.data.player.user.discriminator}`}
            target="_blank"
            rel="noreferrer"
            className="default"
            title="View Profile on smash.gg"
          >
            <SmashGG />
          </a>
        )}

        {(p?.user?.authorizations || []).map((auth) => (
          <a
            key={auth?.type}
            href={auth?.url}
            target="_blank"
            rel="noreferrer"
            className="default"
          >
            {auth?.type === "TWITCH" ? <Twitch /> : <Twitter />}
          </a>
        ))}
      </section>
      <section className={classNames(styles.meta, classes)}>
        <div>
          <Coins />
          {!pr.isValidating && id && moneyFmt.format(pr.data?.earnings ?? 0)}
          {!id && <span className={styles.blank} />}
        </div>
        {p?.user?.location?.country && (
          <div>
            <Globe />
            {p?.user?.location?.country}
          </div>
        )}
      </section>

      <section
        className={classNames(styles.pr, classes, {
          [styles.preloader]: pr.isValidating,
        })}
      >
        <figure>
          <div>
            {id &&
              !pr.isValidating &&
              (pr.data?.pr?.powerRanking || <span className={styles.blank} />)}
            {!id && <span className={styles.blank} />}
          </div>
          <figcaption>
            PR {pr.data?.pr?.region ? `(${pr.data?.pr?.region})` : ""}
          </figcaption>
        </figure>
        <figure>
          <div>
            {id && !pr.isValidating && (pr.data?.pr?.top8 || 0)}
            {!id && <span className={styles.blank} />}
          </div>
          <figcaption>Top 8</figcaption>
        </figure>
        <figure>
          <div>
            {id && !pr.isValidating && (pr.data?.pr?.top32 || 0)}
            {!id && <span className={styles.blank} />}
          </div>
          <figcaption>Top 32</figcaption>
        </figure>
        <figure>
          <div>
            {id && !pr.isValidating && (pr.data?.pr?.gold || 0)}
            {!id && <span className={styles.blank} />}
          </div>
          <figcaption>Gold</figcaption>
        </figure>
        <figure>
          <div>
            {id && !pr.isValidating && (pr.data?.pr?.silver || 0)}
            {!id && <span className={styles.blank} />}
          </div>
          <figcaption>Silver</figcaption>
        </figure>
        <figure>
          <div>
            {id && !pr.isValidating && (pr.data?.pr?.bronze || 0)}
            {!id && <span className={styles.blank} />}
          </div>
          <figcaption>Bronze</figcaption>
        </figure>
      </section>
    </>
  );
}
