import { useMatchupScores } from "../../hooks/data/db";
import styles from "./style.module.scss";

export default function MatchupCount({
  entrant1SmashIds,
  entrant2SmashIds,
  gameMode,
  isOfficial,
}: {
  entrant1SmashIds: (number | undefined)[];
  entrant2SmashIds: (number | undefined)[];
  gameMode: number;
  isOfficial?: boolean;
}) {
  const { data, isValidating } = useMatchupScores(
    entrant1SmashIds,
    entrant2SmashIds,
    gameMode,
    isOfficial
  );

  const stats = data?.matchups?.[0];

  const ratios = stats
    ? [stats.matches, stats.games].map(([a, b]) => {
        const s = a + b;
        return s ? a / s : 0.5;
      })
    : [0.5, 0.5];
  const percents = ratios.map((x) => `${(x * 100).toFixed(0)}%`);

  return (
    <div className={styles.matchups}>
      <span>Matches</span>
      <span>Games</span>
      <div className={styles.score}>
        {isValidating ? (
          <span className={styles.preloader} />
        ) : (
          <>
            {stats?.matches[0] || 0} <span className={styles.dash}>–</span>{" "}
            {stats?.matches[1] || 0}
            <span className={styles.percent}>({percents[0]})</span>
          </>
        )}
      </div>
      <div className={styles.score}>
        {isValidating ? (
          <span className={styles.preloader} />
        ) : (
          <>
            {stats?.games[0] || 0} <span className={styles.dash}>–</span>{" "}
            {stats?.games[1] || 0}
            <span className={styles.percent}>({percents[1]})</span>
          </>
        )}
      </div>
      <div>
        <div className={styles.progress}>
          <div style={{ width: percents[0] }} />
          <div />
        </div>
      </div>
      <div>
        <div className={styles.progress}>
          <div style={{ width: percents[1] }} />
          <div />
        </div>
      </div>
    </div>
  );
}
