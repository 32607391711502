import useSWRImmutable from "swr/immutable";
import useSWRInfinite from "swr/infinite";
import { PlayerSearch, SearchResult } from "../../types/db";

import { Query } from "../../types/smashgg";
import { laggy } from "../swr-laggy-middleware";

export const DB_API = "https://api.brawltools.com";

export const SMASH_API = "https://api.smash.gg/gql/alpha";

export function smashFetcher(query: any, variables: any) {
  return fetch(SMASH_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_SMASH_API_KEY}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })
    .then((res) => res.json())
    .then((data) => data.data) as Promise<Query>;
}

export function useDBPostSWR<T = any>(
  endpoint: string,
  data: any,
  skip?: boolean
) {
  return useSWRImmutable<T>(
    skip ? null : [endpoint, data],
    (endpoint, data) =>
      fetch(DB_API + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    { dedupingInterval: 10000 }
  );
}

export function useDBGetSWR<T = any>(endpoint: string, skip?: boolean) {
  return useSWRImmutable<T>(
    skip ? null : endpoint,
    (endpoint) => fetch(DB_API + endpoint).then((res) => res.json()),
    { dedupingInterval: 10000 }
  );
}

export function useDBPostInfiniteSWR<T = any>(
  getKey: (index: number, prev: T) => { endpoint: string; data?: any } | null
) {
  return useSWRInfinite<T>(
    getKey,
    ({ endpoint, data }) =>
      fetch(DB_API + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    {
      revalidateIfStale: false,
      revalidateAll: false,
      revalidateFirstPage: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 10000,
    }
  );
}

export function useSmashSWR(
  query: string,
  variables: { [key: string]: any } = {},
  skip?: boolean
) {
  return useSWRImmutable<Query>(skip ? null : [query, variables], smashFetcher);
}

export function useSearchResults(
  query: string,
  eventSlug?: string,
  gameMode?: number
) {
  return useSWRImmutable<SearchResult[]>(
    [query, eventSlug, gameMode],
    async (query: string, eventSlug?: string) => {
      let results: SearchResult[] = [];
      query = query.trim();
      if (!query) return [];
      if (eventSlug) {
        // Search through active tournament.
        const data = await fetch(SMASH_API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_SMASH_API_KEY}`,
          },
          body: JSON.stringify({
            query: `query ($slug: String!, $q: String!) {
              event(slug: $slug) {
                entrants(query: { perPage: 5, page: 1, filter: { name: $q } }) {
                  nodes {
                    name
                    initialSeedNum
                    team {
                      members {
                        player { id }
                      }
                    }
                    participants {
                      player { id }
                    }
                   }
                }
              }
            }`,
            variables: { slug: eventSlug, q: query },
          }),
        })
          .then((r) => r.json())
          .then((r) => r.data as Query);

        results =
          data.event?.entrants?.nodes
            ?.sort((a, b) => {
              const x = a?.initialSeedNum || Infinity,
                y = b?.initialSeedNum || Infinity;
              const order = x - y;
              if (isNaN(order)) return 0;
              return order;
            })
            .map((e) => {
              const people = (e?.team?.members || e?.participants)?.map(
                (p) => p?.player
              );
              return {
                name: e?.name,
                smashIds: people?.map((p) => p?.id),
                extra: e?.initialSeedNum ? String(e?.initialSeedNum) : null,
              };
            }) || [];
      } else {
        // Query search API
        const key = ["pr1v1", "pr2v2"][(gameMode || 1) - 1] as
          | "pr1v1"
          | "pr2v2";
        const data = await fetch(`${DB_API}/player/search`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query }),
        }).then((r) => r.json() as Promise<PlayerSearch>);
        results = data.searchPlayers?.slice(0, 5).map((res) => ({
          name: res.player.name,
          smashIds: [res.player.smashId],
          extra: res[key] ? `${res[key].region}#${res[key].powerranking}` : null,
        }));
      }
      return results;
    },
    {
      use: [laggy],
    }
  );
}
