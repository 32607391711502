import { DamageGraph } from "../../types/stats";

export default function DamageGraphImage({
  damage,
}: {
  damage: DamageGraph | null;
}) {
  return (
    <div>
      {damage?.graph && (
        <img
          alt="Damage Graph"
          width="100%"
          style={{ margin: "20px 0" }}
          src={damage?.graph}
        />
      )}
    </div>
  );
}
