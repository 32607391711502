import React from "react";
import MatchupCount from "../../components/matchup-count";
import MatchupPlacements from "../../components/placements/matchup";
import { MatchupOpponents } from "../../components/matchup-opponents";
import { TabGroup } from "../../components/tabgroup";
import { useConfig } from "../config";
import styles from "../style.module.scss";
import { EventSeeds } from "../../components/event-seeds";
import { UpcomingEvents } from "../../components/upcoming-events";

export default function MiddleColumn({
  p1,
  p2,
}: {
  p1: number | undefined;
  p2: number | undefined;
}) {
  const config = useConfig();

  const middle = {
    matchup: p1 && p2 && p1 !== p2,
    opponents: p1,
    seeds: !!config.eventSlug,
  };

  const tabs: { [key: string]: React.ReactNode } = {};

  const getLeftURL = (opp: number[]) => {
    const ids = [opp[0], p2];
    return "/singles/" + ids.join("/");
  };
  const getRightURL = (opp: number[]) => {
    const ids = [p1, opp[0]];
    return "/singles/" + ids.join("/");
  };

  if (middle.matchup)
    tabs.Matchup = (
      <MatchupPlacements
        entrant1SmashIds={[p1]}
        entrant2SmashIds={[p2]}
        gameMode={1}
        isOfficial={config.isOfficial}
      />
    );

  if (middle.opponents)
    tabs.Opponents = (
      <MatchupOpponents
        entrant1SmashIds={[p1]}
        entrant2SmashIds={[p2]}
        gameMode={1}
        isOfficial={config.isOfficial}
        getLeftURL={getLeftURL}
        getRightURL={getRightURL}
      />
    );

  if (middle.seeds)
    tabs.Seeds = (
      <EventSeeds
        slug={config.eventSlug!}
        getLeftURL={getLeftURL}
        getRightURL={getRightURL}
      />
    );

  tabs.Upcoming = <UpcomingEvents />;

  return (
    <div className={styles.middle}>
      {middle.matchup && (
        <MatchupCount
          entrant1SmashIds={[p1]}
          entrant2SmashIds={[p2]}
          gameMode={1}
          isOfficial={config.isOfficial}
        />
      )}
      <TabGroup tabs={tabs} />
    </div>
  );
}
