import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useRef } from "react";
import { pStatic } from "../../constants";
import { useMatchupMatches, useMatchupPlacements } from "../../hooks/data/db";
import useOnScreen from "../../hooks/use-on-screen";
import { MatchupPlacement } from "../../types/db";
import SmashGG from "../icons/smashgg";
import { formatScore, preprocessLegends } from "./helpers";
import styles from "./style.module.scss";

type MatchupPlacementEvent = MatchupPlacement["matchupPlacements"][0];

function MatchupMatches({
  entrant1SmashIds,
  entrant2SmashIds,
  event,
  noLegends,
}: {
  entrant1SmashIds: (number | undefined)[];
  entrant2SmashIds: (number | undefined)[];
  event: MatchupPlacementEvent;
  noLegends?: boolean;
}) {
  const { data } = useMatchupMatches(
    entrant1SmashIds,
    entrant2SmashIds,
    event.tournament.slug
  );

  const hasMatches = !!data?.matchupMatches?.length;
  const winnerClasses = classNames({
    [styles.left]: event.placements[0] < event.placements[1],
    [styles.right]: event.placements[1] < event.placements[0],
  });

  return (
    <>
      <tr className={classNames(styles.event, winnerClasses)}>
        <td
          rowSpan={Number(hasMatches) + 2}
          className={classNames(styles.placement)}
        >
          {event.placements[0]}
        </td>
        <td className={styles.name}>
          <div className={styles.evt}>{event.tournament.tournamentName}</div>
          <div className={styles.tourney}>{event.tournament.eventName}</div>
        </td>
        <td className={styles.date}>
          {format(event.tournament.startTime * 1000, "MM/dd/yyyy")}
        </td>
        <td
          rowSpan={Number(hasMatches) + 2}
          className={classNames(styles.placement, styles.right)}
        >
          {event.placements[1]}
        </td>
      </tr>
      <tr className={classNames(styles.attributes, winnerClasses)}>
        <td colSpan={2}>
          <div>
            <div>{event.tournament.isOfficial ? "Official" : "Community"}</div>
            <a
              href={`https://smash.gg/${event.tournament.slug}`}
              target="_blank"
              rel="noreferrer"
              className="default"
            >
              <SmashGG />
            </a>
          </div>
        </td>
      </tr>

      {hasMatches && (
        <tr className={classNames(styles.matches, winnerClasses)}>
          <td colSpan={2}>
            <section
              className={classNames(styles.container, {
                [styles.noLegends]: noLegends,
              })}
            >
              {data?.matchupMatches.map((match) => {
                return (
                  <React.Fragment key={match.matchId}>
                    <div className={styles.legends}>
                      {preprocessLegends(match.legends[0]).map((x, i) => (
                        <img
                          key={i}
                          src={pStatic(`/heads/${x}.png`)}
                          alt={x}
                          title={x}
                          className={classNames({
                            [styles.unknown]: x === "UNKNOWN",
                          })}
                        />
                      ))}
                    </div>
                    <div
                      className={styles.score}
                      style={{
                        justifyContent: "flex-end",
                      }}
                    >
                      {formatScore(match.scores[0])}
                    </div>
                    <div className={styles.divider}>—</div>
                    <div className={styles.score}>
                      {formatScore(match.scores[1])}
                    </div>
                    <div className={classNames(styles.legends, styles.flip)}>
                      {preprocessLegends(match.legends[1]).map((x, i) => (
                        <img
                          key={i}
                          src={pStatic(`/heads/${x}.png`)}
                          alt={x}
                          title={x}
                          className={classNames({
                            [styles.unknown]: x === "UNKNOWN",
                          })}
                        />
                      ))}
                    </div>
                  </React.Fragment>
                );
              })}
            </section>
            {/* { && (
          <div className={styles.noMatches}>No matches found.</div>
        )}
        {isLoadingMatches && (
          <div className={styles.noMatches} style={{ opacity: 0.5 }}>
            Loading...
          </div>
        )} */}
          </td>
        </tr>
      )}
    </>
  );
}

export default function MatchupPlacements({
  entrant1SmashIds,
  entrant2SmashIds,
  gameMode,
  isOfficial,
}: {
  entrant1SmashIds: (number | undefined)[];
  entrant2SmashIds: (number | undefined)[];
  gameMode: number;
  isOfficial?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(ref);

  const {
    data: placements,
    isValidating,
    setSize,
  } = useMatchupPlacements(
    entrant1SmashIds,
    entrant2SmashIds,
    gameMode,
    isOfficial
  );

  useEffect(() => {
    if (isOnScreen) setSize((size) => size + 1);
  }, [isOnScreen, setSize, placements?.length]);

  const events =
    placements
      ?.map((d) => d.matchupPlacements)
      .filter((x) => x)
      .flat() || [];
  return (
    <section className={classNames(styles.placements, styles.matchup)}>
      <table className={styles.placementTable}>
        <tbody>
          {events.map((evt) => {
            return (
              <MatchupMatches
                key={evt.tournament.slug}
                event={evt}
                entrant1SmashIds={entrant1SmashIds}
                entrant2SmashIds={entrant2SmashIds}
                noLegends={gameMode !== 1}
              />
            );
          })}

          {isValidating &&
            new Array(10).fill(null).map((_, i) => (
              <tr
                key={i}
                className={classNames(styles.preloader, styles.event)}
              >
                <td colSpan={4} />
              </tr>
            ))}
        </tbody>
      </table>
      <div ref={ref} />
    </section>
  );
}
