import classNames from "classnames";
import { useEffect } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import styles from "./style.module.scss";

export function TabGroup({
  tabs,
}: {
  tabs: { [key: string]: React.ReactNode };
}) {
  const [tab, setTab] = useQueryParam(
    "tab",
    withDefault(StringParam, Object.keys(tabs)[0])
  );

  useEffect(() => {
    if (!tabs[tab]) setTab(Object.keys(tabs)[0]);
  }, [tabs, tab, setTab]);

  return (
    <div className={styles.tabGroup}>
      <header>
        {Object.keys(tabs).map((key) => (
          <div
            className={classNames({ [styles.active]: key === tab })}
            onClick={() => setTab(key)}
            key={key}
          >
            {key}
          </div>
        ))}
      </header>
      <main>{tabs[tab]}</main>
    </div>
  );
}
