import classNames from "classnames";
import { Link } from "react-router-dom";
import { LEGENDS, pStatic, STANCES } from "../../constants";
import { BestPostGame, PostGame } from "../../types/stats";
import { classBest } from "./helpers";
import styles from "./style.module.scss";

const getWeaponName = (url?: string) =>
  url?.match(/\/(\w+)$/)?.[1].replace(/^(.)/, (match, p1) => p1.toUpperCase());

export default function PlayerInfo({
  isSingles,
  isDoubles,
  flipped,
  loading,
  stat,
  best,
  i,
}: {
  isSingles?: boolean;
  isDoubles?: boolean;
  flipped?: boolean;
  loading?: boolean;
  stat: PostGame[0] | null;
  best: BestPostGame;
  i: number;
}) {
  const unarmedPercentageHeld = Math.max(
    stat ? 100 - stat.weapon1PercentageHeld - stat.weapon2PercentageHeld : 0,
    0
  );

  const unarmedDamage = Math.max(
    stat ? stat.damageDealt - stat.weapon1Damage - stat.weapon2Damage : 0,
    0
  );
  const unarmedDamageTaken = Math.max(
    stat
      ? stat.damageTaken - stat.weapon1DamageTaken - stat.weapon2DamageTaken
      : 0,
    0
  );

  return (
    <div className={classNames(styles.stat, { [styles.flipped]: flipped })}>
      <header>
        <div className={styles.legend}>
          <img
            src={stat?.legendHead || pStatic("/heads/UNKNOWN.png")}
            alt={stat?.legend || "UNKNOWN"}
            title={LEGENDS.get(stat?.legend || "UNKNOWN")}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              const src = pStatic(`/heads/${stat?.legend || "UNKNOWN"}.png`);
              if (target.src === src)
                target.src = pStatic("/heads/UNKNOWN.png");
              else target.src = src;
            }}
          />
        </div>
        <div>
          <h2>
            <Link
              to={`/${isDoubles ? "doubles" : "singles"}/${stat?.playerId}`}
            >
              {stat?.playerName}
            </Link>
          </h2>
          <h3>
            {stat?.legendName}
            <span style={{ margin: "0 4px" }}>&middot;</span>
            <span>{STANCES.get(stat?.stance || "")}</span>
          </h3>
        </div>
      </header>
      <section className={styles.damage}>
        <figure className={classBest(i, best.damageDealt)}>
          <div>{(stat?.damageDealt || 0).toFixed(0)}</div>
          <figcaption>Dealt</figcaption>
        </figure>
        <figure className={classBest(i, best.damageTaken)}>
          <div>{(stat?.damageTaken || 0).toFixed(0)}</div>
          <figcaption>Taken</figcaption>
        </figure>
        {isSingles ? (
          <figure className={classBest(i, best.damagePerEngagement)}>
            <div>{(stat?.damagePerEngagement || 0).toFixed(0)}</div>
            <figcaption>Per Eng.</figcaption>
          </figure>
        ) : (
          <figure className={classBest(i, best.damageTeam)}>
            <div>{(stat?.damageTeam || 0).toFixed(0)}</div>
            <figcaption>Team</figcaption>
          </figure>
        )}
      </section>
      <section className={styles.attackType}>
        <div>
          <span>Signatures</span>
        </div>
        <div>
          <span>Light Attacks</span>
        </div>
        <figure className={classBest(i, best.signatureCount)}>
          <div>{(stat?.signatureCount || 0).toFixed(0)}</div>
          <figcaption>Count</figcaption>
        </figure>
        <figure className={classBest(i, best.signatureAccuracy)}>
          <div>
            {(stat?.signatureCount || 0) === 0 ? (
              <span className={styles.na}>N/A</span>
            ) : (
              `${stat?.signatureAccuracy || 0}%`
            )}
          </div>
          <figcaption>Acc.</figcaption>
        </figure>
        <figure className={classBest(i, best.lightAttackCount)}>
          <div>{(stat?.lightAttackCount || 0).toFixed(0)}</div>
          <figcaption>Count</figcaption>
        </figure>
        <figure className={classBest(i, best.lightAttackAccuracy)}>
          <div>
            {(stat?.lightAttackCount || 0) === 0 ? (
              <span className={styles.na}>N/A</span>
            ) : (
              `${stat?.lightAttackAccuracy || 0}%`
            )}
          </div>
          <figcaption>Acc.</figcaption>
        </figure>
      </section>
      <section className={styles.weaponDamage}>
        <section>
          <header>
            <img
              src={`${stat?.weapon1Name}.png`}
              alt={getWeaponName(stat?.weapon1Name)}
              title={getWeaponName(stat?.weapon1Name)}
            />
            <div>
              <h4>{getWeaponName(stat?.weapon1Name)}</h4>
              <h5>{(stat?.weapon1PercentageHeld || 0).toFixed(0)}% EQUIPPED</h5>
            </div>
          </header>
          <figure>
            <div>{(stat?.weapon1Damage || 0).toFixed(0)}</div>
            <figcaption>Dealt</figcaption>
          </figure>
          <figure>
            <div>{(stat?.weapon1DamageTaken || 0).toFixed(0)}</div>
            <figcaption>Taken</figcaption>
          </figure>
        </section>
        <section>
          <header>
            <img
              src={`${stat?.weapon2Name}.png`}
              alt={getWeaponName(stat?.weapon2Name)}
              title={getWeaponName(stat?.weapon2Name)}
            />
            <div>
              <h4>{getWeaponName(stat?.weapon2Name)}</h4>
              <h5>{(stat?.weapon2PercentageHeld || 0).toFixed(0)}% EQUIPPED</h5>
            </div>
          </header>
          <figure>
            <div>{(stat?.weapon2Damage || 0).toFixed(0)}</div>
            <figcaption>Dealt</figcaption>
          </figure>
          <figure>
            <div>{(stat?.weapon2DamageTaken || 0).toFixed(0)}</div>
            <figcaption>Taken</figcaption>
          </figure>
        </section>
        <section>
          <header>
            <img
              src={pStatic(`/weapons/unarmed.png`)}
              alt={"Unarmed"}
              title={"Unarmed"}
            />
            <div>
              <h4>Unarmed</h4>
              <h5>{unarmedPercentageHeld.toFixed(0)}% EQUIPPED</h5>
            </div>
          </header>
          <figure>
            <div>{unarmedDamage.toFixed(0)}</div>
            <figcaption>Dealt</figcaption>
          </figure>
          <figure>
            <div>{unarmedDamageTaken.toFixed(0)}</div>
            <figcaption>Taken</figcaption>
          </figure>
        </section>
      </section>
      <section className={styles.damage}>
        <figure className={classBest(i, best.weaponThrows)}>
          <div>{(stat?.weaponThrows || 0).toFixed(0)}</div>
          <figcaption>Throws</figcaption>
        </figure>
        <figure className={classBest(i, best.dodgesCount)}>
          <div>{(stat?.dodgesCount || 0).toFixed(0)}</div>
          <figcaption>Dodges</figcaption>
        </figure>
        <figure className={classBest(i, best.dashesCount)}>
          <div>{(stat?.dashesCount || 0).toFixed(0)}</div>
          <figcaption>Dashes</figcaption>
        </figure>
      </section>
    </div>
  );
}
