import classNames from "classnames";
import { User, Users } from "lucide-react";
import { useEffect, useState } from "react";
import { useSGGEvent } from "../../hooks/data/smashgg";

import styles from "./style.module.scss";

const eventSlugRegex = /(tournament\/[a-z0-9-]+\/event\/[a-z0-9-]+)/i;
const tournamentSlugRegex =
  /(?:tournament\/([a-z0-9-]+)\/?|^([a-z0-9-]+)(?:\/event.*)?\/?$)/i;

const getEventSlug = (i: string) => {
  const match = i.match(eventSlugRegex);
  return match ? match[1] : null;
};

const getTournamentSlug = (i: string) => {
  const match = i.match(tournamentSlugRegex);
  return match ? match[1] ?? match[2] : null;
};

export function EventSelector({
  slug,
  setSlug,
}: {
  slug: string | undefined;
  setSlug: (slug: string | undefined) => void;
}) {
  const [editing, setEditing] = useState(false);
  const [_slug, _setSlug] = useState(slug);
  useEffect(() => _setSlug(slug), [slug]);

  const { data: query, isValidating } = useSGGEvent(slug);
  const event = query?.event;
  return (
    <div className={styles.editor}>
      <label
        className={classNames(styles.event, { [styles.editing]: editing })}
        onClick={() => setEditing(true)}
      >
        <div>
          {editing ? (
            <input
              placeholder="Event Slug"
              autoFocus
              type="text"
              value={_slug || ""}
              onChange={(e) => _setSlug(e.target.value)}
              onBlur={() => {
                const sl = _slug || "";
                const s =
                  getEventSlug(sl) || getTournamentSlug(sl) || undefined;
                setSlug(s);
                _setSlug(s);
                setEditing(false);
              }}
              onKeyDown={(e) => {
                const target = e.target as HTMLInputElement;
                if (e.key === "Enter") return target.blur();
                if (e.key === "Escape") {
                  _setSlug(slug);
                  setTimeout(() => target.blur());
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                const pasted = e.clipboardData.getData("text");
                const s = getEventSlug(pasted);
                _setSlug(s || pasted);
              }}
            />
          ) : slug ? (
            isValidating ? (
              <h3 className={styles.status}>Loading...</h3>
            ) : (
              <>
                <h3>{event?.tournament?.name}</h3>
                <div>{event?.name}</div>
              </>
            )
          ) : (
            <h3 className={styles.status} style={{ alignSelf: "flex-start" }}>
              No event selected.
            </h3>
          )}
        </div>
      </label>
      <div
        className={classNames(
          {
            [styles.hide]: !event?.tournament?.events?.length,
            [styles.show]: editing,
          },
          styles.events
        )}
      >
        <ul>
          {event?.tournament?.events?.map((evt) => (
            <li
              className={classNames({ [styles.active]: evt?.slug === slug })}
              key={evt?.slug}
              onMouseDown={() => setSlug(evt?.slug)}
            >
              {evt?.teamRosterSize ? <Users /> : <User />}
              {evt?.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
