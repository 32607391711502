import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { useSGGUpcomingEvents } from "../../hooks/data/smashgg";
import { Event } from "../../types/smashgg";
import { format } from "date-fns";

import styles from "./style.module.scss";
import { User, Users } from "lucide-react";
import classNames from "classnames";

function EventRow({ event }: { event: Event | null }) {
  const [slug, setSlug] = useQueryParam(
    "event",
    withDefault(StringParam, undefined)
  );

  if (!event) return null;
  return (
    <div
      onClick={() => setSlug(event.slug)}
      className={classNames({ [styles.active]: slug === event.slug })}
    >
      <header>
        <div>
          <h3>{event.tournament!.name}</h3>
          <h4>{event.name}</h4>
        </div>
      </header>
      <main>
        <span>
          {!event.teamRosterSize ? (
            <>
              <User />
              Singles
            </>
          ) : event.teamRosterSize.minPlayers === 2 ? (
            <>
              <Users />
              Doubles
            </>
          ) : (
            <>Teams</>
          )}
        </span>
        <div className={styles.date}>
          {format(event.startAt! * 1000, "MM/dd/yy")}
        </div>
      </main>
    </div>
  );
}

export function UpcomingEvents() {
  const { data } = useSGGUpcomingEvents();
  return (
    <div className={styles.upcoming}>
      {data?.league?.events?.nodes?.map((evt) => (
        <EventRow key={evt?.slug} event={evt} />
      ))}
    </div>
  );
}
