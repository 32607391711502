import classNames from "classnames";
import React, { InputHTMLAttributes } from "react";

import styles from "./style.module.scss";

export function Select({
  className,
  children,
  ...props
}: InputHTMLAttributes<HTMLSelectElement>) {
  return (
    <select className={classNames(styles.select, className)} {...props}>
      {children}
    </select>
  );
}

export function Label({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <label className={styles.label}>
      <span>{label}</span>
      {children}
    </label>
  );
}

export function InputRow({ children }: { children: React.ReactNode }) {
  return <div className={styles.inputRow}>{children}</div>;
}
