import classNames from "classnames";
import { useRef } from "react";
import PlayerPlacements from "../../components/placements/player";
import PlayerInfo from "../../components/player-info";
import { useConfig } from "../config";

import styles from "../style.module.scss";
import { makePath } from "./helpers";

export default function EntrantColumn({
  ids,
  setIds,
  flipped,
  disabled,
}: {
  ids: number[];
  setIds?: (ids: number[], error?: boolean) => void;
  flipped?: boolean;
  disabled?: boolean;
}) {
  const config = useConfig();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      className={classNames(styles.column, { [styles.flipped]: flipped })}
    >
      <PlayerInfo
        id={ids[0]}
        gameMode={2}
        setIds={
          setIds
            ? (i, error) => {
                setIds([...i, ids[1]].filter((x) => x) as number[], error);
              }
            : undefined
        }
        eventSlug={config.eventSlug}
        disabled={disabled}
        flipped={flipped}
      />

      <PlayerInfo
        id={ids[1]}
        gameMode={2}
        setIds={
          setIds
            ? (i, error) => {
                if (i.length === 2)
                  return setIds(i.filter((x) => x) as number[], error);
                setIds([ids[0], i[0]].filter((x) => x) as number[], error);
              }
            : undefined
        }
        eventSlug={config.eventSlug}
        disabled={disabled || !ids[0]}
        flipped={flipped}
      />

      <PlayerPlacements
        entrantSmashIds={ids}
        gameMode={2}
        flipped={flipped}
        isOfficial={config.isOfficial}
        getOpponentURL={(opp) => {
          const things = [ids, opp];
          flipped && things.reverse();
          return makePath(things[0], things[1]);
        }}
      />
    </div>
  );
}
