import { PostGameStats } from "../../components/live-stats/post-game";
import PreGameStats from "../../components/live-stats/pre-game";
import { StatusProvider } from "../../components/live-stats/status-bar";
import { useLiveStats } from "../../hooks/data/stats";

export default function Wrapper({ url, view }: { url: string; view: string }) {
  const { pre, post, best, damage, loading, connected } = useLiveStats(url);

  const preIds = pre
    .map((p) => p.id)
    .sort()
    .join(",");
  const postIds = (post || [])
    .map((p) => p.playerId)
    .sort()
    .join(",");

  const autoPre = preIds !== postIds;
  const visible = view === "auto" ? (autoPre ? "pre" : "post") : view;

  const isSingles = pre.length === 2,
    isDoubles = pre.length === 4;

  return (
    <StatusProvider
      value={{
        connected,
        isDoubles,
        isSingles,
        players: pre.map((p) => ({
          id: p.id,
          head: p.face,
          legend: p.legend,
          score: p.score,
          name: p.name,
        })),
      }}
    >
      {visible === "pre" && <PreGameStats pre={pre} connected={connected} />}
      {visible === "post" && (
        <PostGameStats
          post={post}
          best={best}
          damage={damage}
          loading={loading}
        />
      )}
    </StatusProvider>
  );
}
