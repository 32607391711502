import { useCallback, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ConfigControls, ConfigProvider, useConfigParams } from "../config";
import MiddleColumn from "./middle-column";
import PlayerColumn from "./player-column";
import styles from "../style.module.scss";
import useGradientBG from "../../hooks/use-gradient-bg";

function useValidateURL() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const p1 = params.p1 ? parseInt(params.p1, 10) : undefined;
  const p2 = params.p2 ? parseInt(params.p2, 10) : undefined;

  const pathname = ["singles", p1, p2]
    .filter((x) => x)
    .map((x) => `/${x}`)
    .join("");

  useEffect(() => {
    navigate(
      {
        pathname,
        search: location.search,
      },
      { replace: true }
    );
  }, [pathname, location.search, navigate]);

  return { p1, p2 };
}

export default function SinglesPage({
  setLink,
}: {
  setLink: (link: string) => void;
}) {
  useGradientBG();

  const navigate = useNavigate();
  const location = useLocation();

  const { p1, p2 } = useValidateURL();

  const config = useConfigParams(1, setLink);

  const bail = useCallback(
    (p1Valid: boolean, p2Valid: boolean) => {
      let pathname: string;
      if (!p1Valid && !p2Valid) {
        pathname = "/singles";
      } else if (!p1Valid && p2Valid) {
        pathname = `/singles/${p2}`;
      } else if (p1Valid && !p2Valid) {
        pathname = `/singles/${p1}`;
      } else return;
      navigate(
        {
          pathname,
          search: location.search,
        },
        { replace: true }
      );
    },
    [location.search, navigate, p1, p2]
  );

  useEffect(() => {
    bail(
      p1 === undefined ? false : !isNaN(p1),
      p2 === undefined ? false : !isNaN(p2)
    );
  }, [p1, p2, bail]);

  return (
    <ConfigProvider value={config}>
      <ConfigControls />

      <div className={styles.container}>
        <PlayerColumn
          id={p1 || undefined}
          setId={(id) =>
            id
              ? navigate({
                  pathname: `/singles/${id}/${p2}`,
                  search: location.search,
                })
              : bail(false, !!p2)
          }
        />
        <MiddleColumn p1={p1} p2={p2} />
        <PlayerColumn
          flipped
          id={p2 || undefined}
          disabled={!p1}
          setId={(id) =>
            id
              ? navigate({
                  pathname: `/singles/${p1}/${id}`,
                  search: location.search,
                })
              : bail(!!p1, false)
          }
        />
      </div>
    </ConfigProvider>
  );
}
