import styles from "./style.module.scss";

export const unique = (v: any, i: number, a: any[]) => v && a.indexOf(v) === i;
export const preprocessLegends = (legends: string[]) => {
  const f = legends.filter(unique);
  return f.length === 0 ? ["UNKNOWN"] : f;
};
export const formatScore = (score: number) =>
  score === -1 ? <span className={styles.dq}>DQ</span> : score;

export const didWin = (scores: [number, number]) => scores[0] > scores[1];
