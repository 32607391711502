import { useAuth0 } from "@auth0/auth0-react";
import Button from "../ui/button";
import styles from "./style.module.scss";

export default function AuthMessage() {
  const { user, logout } = useAuth0();
  return (
    <div className={styles.message}>
      <div>
        <div className={styles.logo}>
          <img src="/logo.png" alt="Brawlhalla Stats" />
          <h1>
            <span>Brawlhalla</span> Pro Stats
          </h1>
        </div>
        <div className={styles.subtext}>
          {user?.name ? `Hey ${user.name},` : `Hey,`}
        </div>
        <div className={styles.body}>
          You don't have the necessary permissions to view this page.
        </div>
        <div className={styles.subtext}>
          Contact <code>preyneyv#1111</code> on Discord if this is in error.
        </div>
        <div>
          <Button onClick={() => logout({ returnTo: window.location.origin })}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}
