import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { useAuth0 } from "@auth0/auth0-react";
import { QueryParamProvider } from "use-query-params";
import AuthMessage from "./auth/message";
import AuthLoading from "./auth/loading";

function RouteAdapter({ children }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
}

function AppWrapper() {
  // return <App />;
  const { isLoading, isAuthenticated, getIdTokenClaims } = useAuth0();

  const [hasRole, setHasRole] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      setRoleLoading(isLoading);
      setHasRole(true);
      return;
    }
    setRoleLoading(true);
    getIdTokenClaims().then((token) => {
      setRoleLoading(false);
      setHasRole(
        token && token["https://brawltools.com/roles"].includes("Stats")
      );
    });
  }, [isAuthenticated, isLoading, getIdTokenClaims]);

  if (roleLoading) {
    return <AuthLoading />;
  }

  if (!hasRole) {
    return <AuthMessage />;
  }

  return <App />;
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={RouteAdapter}>
      {process.env.USE_AUTH0 ? (
        <Auth0ProviderWithHistory>
          <AppWrapper />
        </Auth0ProviderWithHistory>
      ) : (
        <App />
      )}
    </QueryParamProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
