export function makePath(
  e1: (number | undefined)[],
  e2: (number | undefined)[]
) {
  return ["/doubles"]
    .concat(
      [e1, e2]
        .map((x) => x.filter((y) => y))
        .filter((x) => x.length)
        .map((x) => x.join("-"))
    )
    .join("/");
}
