import { useConfig } from "../config";
import MatchupPlacements from "../../components/placements/matchup";
import MatchupCount from "../../components/matchup-count";
import React from "react";
import { MatchupOpponents } from "../../components/matchup-opponents";
import { makePath } from "./helpers";

import styles from "../style.module.scss";
import { TabGroup } from "../../components/tabgroup";
import { EventSeeds } from "../../components/event-seeds";
import { UpcomingEvents } from "../../components/upcoming-events";

function compareArrays(a1: number[], a2: number[]) {
  return a1.some((x) => a2.includes(x)) && a2.some((x) => a1.includes(x));
}

export default function MiddleColumn({
  e1,
  e2,
}: {
  e1: number[];
  e2: number[];
}) {
  const config = useConfig();

  const middle = {
    matchup: Boolean(e1.length && e2.length && !compareArrays(e1, e2)),
    opponents: e1.length,
    seeds: !!config.eventSlug,
  };

  const tabs: { [key: string]: React.ReactNode } = {};

  if (middle.matchup)
    tabs.Matchup = (
      <MatchupPlacements
        entrant1SmashIds={e1}
        entrant2SmashIds={e2}
        gameMode={2}
        isOfficial={config.isOfficial}
      />
    );

  if (middle.opponents)
    tabs.Opponents = (
      <MatchupOpponents
        entrant1SmashIds={e1}
        entrant2SmashIds={e2}
        gameMode={2}
        isOfficial={config.isOfficial}
        getLeftURL={(opp) => makePath(opp, e2)}
        getRightURL={(opp) => makePath(e1, opp)}
      />
    );

  if (middle.seeds)
    tabs.Seeds = (
      <EventSeeds
        slug={config.eventSlug!}
        getLeftURL={(opp) => makePath(opp, e2)}
        getRightURL={(opp) => makePath(e1, opp)}
      />
    );

  tabs.Upcoming = <UpcomingEvents />;

  return (
    <div className={styles.middle}>
      {middle.matchup && (
        <MatchupCount
          entrant1SmashIds={e1}
          entrant2SmashIds={e2}
          gameMode={2}
          isOfficial={config.isOfficial}
        />
      )}
      <TabGroup tabs={tabs} />
    </div>
  );
}
