import { LEGENDS, pStatic } from "../../constants";
import { BestPostGame, PostGame } from "../../types/stats";
import { classBest } from "./helpers";
import styles from "./style.module.scss";

export default function TeamInfo({
  data,
  best,
}: {
  data: PostGame;
  best: BestPostGame;
}) {
  return (
    <div className={styles.team}>
      <main>
        <div className={classBest(0, best.teamDamageDealt)}>
          {data?.[0]?.teamDamageDealt || 0}
        </div>
        <h3>
          <span>Damage Dealt</span>
        </h3>
        <div className={classBest(1, best.teamDamageDealt)}>
          {data?.[1]?.teamDamageDealt || 0}
        </div>

        <div className={classBest(0, best.teamDamageTaken)}>
          {data?.[0]?.teamDamageTaken || 0}
        </div>
        <h3>
          <span>Damage Taken</span>
        </h3>
        <div className={classBest(1, best.teamDamageTaken)}>
          {data?.[1]?.teamDamageTaken || 0}
        </div>

        <div className={classBest(0, best.teamDamageTeam)}>
          {data?.[0]?.teamDamageTeam || 0}
        </div>
        <h3>
          <span>Team Damage</span>
        </h3>
        <div className={classBest(1, best.teamDamageTeam)}>
          {data?.[1]?.teamDamageTeam || 0}
        </div>

        <div className={classBest(0, best.teamLightAttackCount)}>
          {data?.[0]?.teamLightAttackCount || 0}
        </div>
        <h3>
          <span>Light Attack Count</span>
        </h3>
        <div className={classBest(1, best.teamLightAttackCount)}>
          {data?.[1]?.teamLightAttackCount || 0}
        </div>

        <div className={classBest(0, best.teamLightAttackAccuracy)}>
          {(data?.[0]?.teamLightAttackCount || 0) === 0 ? (
            <span className={styles.na}>N/A</span>
          ) : (
            `${data?.[0]?.teamLightAttackAccuracy || 0}%`
          )}
        </div>
        <h3>
          <span>Light Attack Acc.</span>
        </h3>
        <div className={classBest(1, best.teamLightAttackAccuracy)}>
          {(data?.[1]?.teamLightAttackCount || 0) === 0 ? (
            <span className={styles.na}>N/A</span>
          ) : (
            `${data?.[1]?.teamLightAttackAccuracy || 0}%`
          )}
        </div>

        <div className={classBest(0, best.teamSignatureCount)}>
          {data?.[0]?.teamSignatureCount || 0}
        </div>
        <h3>
          <span>Signature Count</span>
        </h3>
        <div className={classBest(1, best.teamSignatureCount)}>
          {data?.[1]?.teamSignatureCount || 0}
        </div>

        <div className={classBest(0, best.teamSignatureAccuracy)}>
          {(data?.[0]?.teamSignatureCount || 0) === 0 ? (
            <span className={styles.na}>N/A</span>
          ) : (
            `${data?.[0]?.teamSignatureAccuracy || 0}%`
          )}
        </div>
        <h3>
          <span>Signature Acc.</span>
        </h3>
        <div className={classBest(1, best.teamSignatureAccuracy)}>
          {(data?.[1]?.teamSignatureCount || 0) === 0 ? (
            <span className={styles.na}>N/A</span>
          ) : (
            `${data?.[1]?.teamSignatureAccuracy || 0}%`
          )}
        </div>
      </main>
    </div>
  );
}
