import { ArrowLeft, ArrowRight } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { useSGGEvent, useSGGSeeds } from "../../hooks/data/smashgg";
import { Seed } from "../../types/smashgg";

import styles from "./style.module.scss";

type GetURL = (ids: number[]) => string;

function SeedRow({
  seed,
  getLeftURL,
  getRightURL,
}: {
  seed: Seed | null;
  getLeftURL: GetURL;
  getRightURL: GetURL;
}) {
  const location = useLocation();

  if (!seed) return null;

  const ids = seed.entrant!.participants!.map((p) => p!.player!.id!);

  return (
    <tr>
      <td className={styles.icon}>
        <Link to={{ pathname: getLeftURL(ids), search: location.search }}>
          <ArrowLeft />
        </Link>
      </td>
      <td className={styles.seed}>{seed.seedNum}</td>
      <td className={styles.name}>{seed.entrant?.name}</td>
      <td className={styles.icon}>
        <Link to={{ pathname: getRightURL(ids), search: location.search }}>
          <ArrowRight />
        </Link>
      </td>
    </tr>
  );
}

export function EventSeeds({
  slug,
  getLeftURL,
  getRightURL,
}: {
  slug: string;
  getLeftURL: GetURL;
  getRightURL: GetURL;
}) {
  const phases = useSGGEvent(slug).data?.event?.phases;
  const phaseId = phases?.[0]?.id;
  const { data } = useSGGSeeds(phaseId);
  const seeds = data?.phase?.seeds?.nodes || [];

  return seeds.length ? (
    <table className={styles.seeds}>
      <thead>
        <tr>
          <th />
          <th className={styles.seed}>#</th>
          <th>Attendee</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data?.phase?.seeds?.nodes?.map((v, i) => (
          <SeedRow
            key={i}
            seed={v}
            getLeftURL={getLeftURL}
            getRightURL={getRightURL}
          />
        ))}
      </tbody>
    </table>
  ) : (
    <div style={{ fontWeight: 500, opacity: 0.5 }}>
      Seeding hasn't been published yet.
    </div>
  );
}
