import { MutableRefObject, useEffect, useState } from "react";

export default function useOnScreen(
  ref: MutableRefObject<HTMLDivElement | null>
) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    const obs = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    obs.observe(ref.current);

    return () => obs.disconnect();
  }, [ref]);

  return isIntersecting;
}
