import classNames from "classnames";
import { ExternalLink } from "lucide-react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LEGENDS, pStatic } from "../../constants";
import styles from "./style.module.scss";

export type ScoreboardPlayer = {
  id: number;
  legend: string;
  head: string;
  score: string;
  name: string;
};

export const StatusContext = React.createContext<{
  isSingles: boolean;
  isDoubles: boolean;
  connected: boolean;
  players: ScoreboardPlayer[];
}>({
  isSingles: false,
  isDoubles: false,
  connected: false,
  players: [],
});

export const StatusProvider = StatusContext.Provider;
export const useStatus = () => useContext(StatusContext);

const ScoreboardRow = ({ p }: { p: ScoreboardPlayer }) => (
  <div className={styles.row}>
    <div className={styles.legend}>
      <img
        className={styles.legend}
        src={p?.head || pStatic("/heads/UNKNOWN.png")}
        alt={p?.legend || "UNKNOWN"}
        title={LEGENDS.get(p?.legend || "UNKNOWN")}
        onError={(e) => {
          const target = e.target as HTMLImageElement;
          const src = pStatic(`/heads/${p?.legend || "UNKNOWN"}.png`);
          if (target.src === src) target.src = pStatic("/heads/UNKNOWN.png");
          else target.src = src;
        }}
      />
    </div>
    <div className={styles.name}>
      <div>{p.name}</div>
      <span>{LEGENDS.get(p.legend || "UNKNOWN")}</span>
    </div>
  </div>
);

export default function StatusBar() {
  const { isSingles, isDoubles, connected, players } = useStatus();
  return (
    <>
      <header className={styles.statusHeader}>
        <div>
          {isSingles && (
            <Link to={`/singles/${players?.[0]?.id}/${players?.[1]?.id}`}>
              View Matchup <ExternalLink />
            </Link>
          )}
          {isDoubles && (
            <Link
              to={`/doubles/${players?.[0]?.id}-${players?.[2]?.id}/${players?.[1]?.id}-${players?.[3]?.id}`}
            >
              View Matchup <ExternalLink />
            </Link>
          )}
        </div>
        <div className={styles.status}>
          {connected ? "Connected" : "Disconnected"}
        </div>
      </header>
      <header className={styles.scoreboard}>
        <div className={styles.scoreboardTeam}>
          {new Array(Math.floor((players?.length || 0) / 2))
            .fill(null)
            .map((_, i) => (
              <ScoreboardRow key={i} p={players?.[i * 2]} />
            ))}
        </div>
        <div className={styles.score}>
          {players?.[0]?.score} <span>–</span> {players?.[1]?.score}
        </div>
        <div className={classNames(styles.flipped, styles.scoreboardTeam)}>
          {new Array(Math.floor((players?.length || 0) / 2))
            .fill(null)
            .map((_, i) => (
              <ScoreboardRow key={i} p={players?.[i * 2 + 1]} />
            ))}
        </div>
      </header>
    </>
  );
}
