import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import SinglesPage from "./pages/singles";

import styles from "./App.module.scss";
import DoublesPage from "./pages/doubles";
import LiveStatsPage from "./pages/live-stats";
import { LogOut } from "lucide-react";
import Button from "./ui/button";
import { useState } from "react";

const times: [number, string][] = [
  [17, "evening"],
  [12, "afternoon"],
  [0, "morning"],
];

const getToD = () => {
  const h = new Date().getHours();
  return times.find(([t]) => t <= h)![1];
};

function App() {
  const { user, logout } = useAuth0();

  const [singlesLink, setSinglesLink] = useState("/singles");
  const [doublesLink, setDoublesLink] = useState("/doubles");
  const [liveStatsLink, setLiveStatsLink] = useState("/live");

  return (
    <>
      <header className={styles.header}>
        <a href="/singles">
          <div className={styles.logo}>
            <img src="/logo.png" alt="Brawlhalla Stats" />
            <h1>
              <span>Brawlhalla</span> ProStats
            </h1>
          </div>
        </a>
        <nav>
          <NavLink to={singlesLink}>Singles</NavLink>
          <NavLink to={doublesLink}>Doubles</NavLink>
          <NavLink to={liveStatsLink}>Live Stats</NavLink>
        </nav>
        <div className={styles.spacer} />
        <aside>
          <span>
            Good {getToD()}
            {process.env.USE_AUTH0 ? "," : "!"}
          </span>
          {process.env.USE_AUTH0 && <div>{user?.name}</div>}
        </aside>
        {process.env.USE_AUTH0 && (
          <Button
            title="Logout"
            icon
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            <LogOut />
          </Button>
        )}
      </header>
      <Routes>
        <Route
          path="/singles"
          element={<SinglesPage setLink={setSinglesLink} />}
        >
          <Route index element={null} />
          <Route path=":p1/:p2" element={null} />
          <Route path=":p1" element={null} />
        </Route>
        <Route
          path="/doubles"
          element={<DoublesPage setLink={setDoublesLink} />}
        >
          <Route index element={null} />
          <Route path=":e1/:e2" element={null} />
          <Route path=":e1" element={null} />
        </Route>
        <Route
          path="/live"
          element={<LiveStatsPage setLink={setLiveStatsLink} />}
        />
        <Route
          path="/live/:source"
          element={<LiveStatsPage setLink={setLiveStatsLink} />}
        />
        <Route path="/" element={<Navigate replace to="/singles" />} />
        <Route
          path="*"
          element={<div>Sorry, we can't find what you're looking for!</div>}
        />
      </Routes>
    </>
  );
}

export default process.env.USE_AUTH0 ? withAuthenticationRequired(App) : App;
// export default withAuthenticationRequired(App);
// export default App;
