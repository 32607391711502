import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import {
  BestPostGame,
  DamageGraph,
  PostGame,
  PreGame,
} from "../../types/stats";
import { resolve } from "../../constants";

function strip(path: string) {
  return path
    .replace("img/legends/", "")
    .replace("img/stances/", "")
    .replace("img/weapons/", "")
    .replace(".png", "");
}

function arg(d: number[], v: number) {
  return new Array(d.length)
    .fill(null)
    .map((_, i) => i)
    .filter((i) => d[i] === v);
}

function argmax(d: number[]) {
  return arg(d, Math.max(...d.filter((v) => !isNaN(v)), -Infinity));
}

function argmin(d: number[]) {
  return arg(d, Math.min(...d.filter((v) => !isNaN(v)), Infinity));
}

function computeBest(data: PostGame): BestPostGame {
  return {
    damageDealt: argmax(data.map((d) => d.damageDealt)),
    damageTaken: argmin(data.map((d) => d.damageTaken)),
    damageTeam: argmin(data.map((d) => d.damageTeam)),
    lightAttackAccuracy: argmax(
      data.map((d) => parseInt(d.lightAttackAccuracy + ""))
    ),
    lightAttackCount: argmax(data.map((d) => d.lightAttackCount)),
    signatureAccuracy: argmax(
      data.map((d) => parseInt(d.signatureAccuracy + ""))
    ),
    signatureCount: argmax(data.map((d) => d.signatureCount)),
    damagePerEngagement: argmax(data.map((d) => d.damagePerEngagement)),

    teamDamageDealt: argmax(data.map((d) => d.teamDamageDealt)),
    teamDamageTaken: argmin(data.map((d) => d.teamDamageTaken)),
    teamDamageTeam: argmin(data.map((d) => d.teamDamageTeam)),
    teamLightAttackAccuracy: argmax(
      data.map((d) => parseInt(d.teamLightAttackAccuracy + ""))
    ),
    teamLightAttackCount: argmax(data.map((d) => d.teamLightAttackCount)),
    teamSignatureAccuracy: argmax(
      data.map((d) => parseInt(d.teamSignatureAccuracy + ""))
    ),
    teamSignatureCount: argmax(data.map((d) => d.teamSignatureCount)),
    weaponThrows: argmax(data.map((d) => d.weaponThrows)),
    dodgesCount: argmax(data.map((d) => d.dodgesCount)),
    dashesCount: argmax(data.map((d) => d.dashesCount)),
  };
}

function processPost(post: PostGame | null) {
  if (!post) return { post: null, best: {} };

  post = post
    .filter((p) => p.playerId)
    .map((d) => ({
      ...d,
      legend: strip(d.legend),
      stance: strip(d.stance),
      weapon1Name: strip(d.weapon1Name),
      weapon2Name: strip(d.weapon2Name),
    }));

  const best = computeBest(post);
  return { post, best };
}

function processPre(pre: PreGame) {
  return pre.map((p) => ({
    ...p,
    legend: strip(p.legend),
    face: resolve(p.face),
  }));
}

/**
 * Load live stats from a URL and connect to the WebSocket to get continuous
 * updates.
 * @param url The root URL for the Stream Toolkit instance to connect to.
 */
export function useLiveStats(url: string) {
  const [data, setData] = useState<{
    post: PostGame | null;
    best: BestPostGame;
  }>({ post: null, best: {} });
  const [pre, setPre] = useState<PreGame>([]);
  const [damage, setDamage] = useState<DamageGraph | null>(null);

  const [loading, setLoading] = useState(true);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    let cancel = () => {};
    (async () => {
      let cancelled = false;
      cancel = () => (cancelled = true);

      setLoading(true);
      setConnected(false);

      setData({ post: null, best: {} });
      setPre([]);

      const [data, pre, damage] = (await Promise.all([
        fetch(`${url}/api/json/stats/stats.json`)
          .then((res) => res.json())
          .catch(() => null),
        fetch(`${url}/api/json/live/game.json`)
          .then((res) => res.json())
          .catch(() => []),
        fetch(`${url}/api/json/stats/damage-graph.json`)
          .then((res) => res.json())
          .catch(() => []),
      ])) as [PostGame | null, PreGame, [DamageGraph]];

      if (cancelled) return;

      setLoading(false);
      setData(processPost(data));
      setPre(processPre(pre));
      setDamage(damage[0] || null);

      const socket = io(url, {
        path: "/api/external",
        reconnectionDelay: 100,
        reconnectionDelayMax: 100,
      });

      cancel = () => socket.disconnect();

      socket.on("writeFile", (file: string | string[], data: string) => {
        if (typeof file === "string") file = [file];
        file = file.join("/");

        if (file === "stats/stats.json") {
          setData(processPost(JSON.parse(data)));
        }

        if (file === "stats/damage-graph.json") {
          setDamage(JSON.parse(data)[0] || null);
        }

        if (file === "live/game.json") {
          setPre(processPre(JSON.parse(data)));
        }
      });

      socket.on("disconnect", () => setConnected(false));
      socket.on("connect", async () => {
        setConnected(true);

        const [data, pre] = (await Promise.all([
          fetch(`${url}/api/json/stats/stats.json`)
            .then((res) => res.json())
            .catch(() => null),
          fetch(`${url}/api/json/live/game.json`)
            .then((res) => res.json())
            .catch(() => []),
          fetch(`${url}/api/json/stats/damage-graph.json`)
            .then((res) => res.json())
            .catch(() => []),
        ])) as [PostGame | null, PreGame, [DamageGraph]];

        if (cancelled) return;

        setLoading(false);
        setData(processPost(data));
        setPre(processPre(pre));
        setDamage(damage[0] || null);
      });
    })();

    return () => void cancel();
  }, [url]);

  console.log(damage);

  return { ...data, pre, damage, loading, connected };
}
