import DoublesEntrantColumn from "../../pages/doubles/entrant-column";
import DoublesMatchupColumn from "../../pages/doubles/middle-column";
import SinglesPlayerColumn from "../../pages/singles/player-column";
import SinglesMatchupColumn from "../../pages/singles/middle-column";
import { PreGame } from "../../types/stats";
import styles from "./style.module.scss";
import { Config, ConfigProvider } from "../../pages/config";
import StatusBar from "./status-bar";
import { useState } from "react";
import classNames from "classnames";

export default function PreGameStats({
  pre,
  connected,
}: {
  pre: PreGame;
  connected: boolean;
}) {
  const isSingles = pre.length === 2,
    isDoubles = pre.length === 4;

  const e1 = [pre[0]?.id, pre[2]?.id].filter((x) => x);
  const e2 = [pre[1]?.id, pre[3]?.id].filter((x) => x);

  const dummyConfig: Config = {
    isOfficial: true,
    eventSlug: undefined,
    year: 2022,
    setIsOfficial: () => {},
    setEventSlug: () => {},
    setYear: () => {},
  };

  const [active, setActive] = useState("left");

  if (!isSingles && !isDoubles) return null;

  return (
    <ConfigProvider value={dummyConfig}>
      <div className={classNames(styles.container, styles.tabgroup)}>
        <div
          className={classNames(styles.left, {
            [styles.active]: active === "left",
          })}
        >
          {isDoubles ? (
            <DoublesEntrantColumn ids={e1} />
          ) : (
            <SinglesPlayerColumn id={pre[0]?.id} />
          )}
        </div>
        <div className={styles.middle}>
          <StatusBar />
          {isDoubles ? (
            <DoublesMatchupColumn e1={e1} e2={e2} />
          ) : (
            <SinglesMatchupColumn p1={pre[0]?.id} p2={pre[1]?.id} />
          )}

          <div className={styles.activeSelector}>
            <div
              className={classNames({ [styles.active]: active === "left" })}
              onClick={() => setActive("left")}
            >
              Left
            </div>
            <div
              className={classNames({ [styles.active]: active === "right" })}
              onClick={() => setActive("right")}
            >
              Right
            </div>
          </div>
        </div>
        <div
          className={classNames(styles.right, {
            [styles.active]: active === "right",
          })}
        >
          {isDoubles ? (
            <DoublesEntrantColumn flipped ids={e2} />
          ) : (
            <SinglesPlayerColumn flipped id={pre[1]?.id} />
          )}
        </div>
      </div>
    </ConfigProvider>
  );
}
