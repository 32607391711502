import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGradientBG from "../../hooks/use-gradient-bg";
import { ConfigControls, ConfigProvider, useConfigParams } from "../config";

import styles from "../style.module.scss";
import EntrantColumn from "./entrant-column";
import { makePath } from "./helpers";
import MiddleColumn from "./middle-column";

function parseEntrant(e: string | undefined) {
  if (!e) return [];
  return e
    .split("-")
    .map((e) => e.trim())
    .map((i) => parseInt(i, 10));
}

function useValidateURL() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  let e1 = parseEntrant(params.e1);
  let e2 = parseEntrant(params.e2);

  e1 = e1.slice(0, 2).filter((x) => x);
  e2 = e2.slice(0, 2).filter((x) => x);
  const pathname = makePath(e1, e2);

  useEffect(() => {
    navigate(
      {
        pathname,
        search: location.search,
      },
      { replace: true }
    );
  }, [pathname, location.search, navigate]);

  return { e1, e2 };
}

export default function DoublesPage({
  setLink,
}: {
  setLink: (link: string) => void;
}) {
  useGradientBG();
  const location = useLocation();
  const navigate = useNavigate();
  const { e1, e2 } = useValidateURL();

  const config = useConfigParams(2, setLink);

  return (
    <ConfigProvider value={config}>
      <ConfigControls />
      <div className={styles.container}>
        <EntrantColumn
          ids={e1}
          setIds={(ids, error) => {
            navigate(
              {
                pathname: makePath(ids, e2),
                search: location.search,
              },
              { replace: !!error }
            );
          }}
        />
        <MiddleColumn e1={e1} e2={e2} />
        <EntrantColumn
          ids={e2}
          disabled={!e1.length}
          setIds={(ids, error) => {
            navigate(
              {
                pathname: makePath(e1, ids),
                search: location.search,
              },
              { replace: !!error }
            );
          }}
          flipped
        />
      </div>
    </ConfigProvider>
  );
}
