import { useEffect } from "react";
import {
  DescribePlayer,
  MatchupMatch,
  MatchupPlacement,
  MatchupScore,
  PlayerLegend,
  PlayerMatch,
  PlayerPlacement,
  PlayerPR,
} from "../../types/db";
import { useDBGetSWR, useDBPostInfiniteSWR, useDBPostSWR } from "./api";

export function useDBPlayer(smashId: number | undefined) {
  return useDBGetSWR<DescribePlayer>(`/player/${smashId}`, !smashId);
}

export function usePlayerPR(smashId: number | undefined, gameMode: number) {
  return useDBPostSWR<PlayerPR>(
    "/player/pr",
    {
      entrantSmashIds: [smashId],
      gameMode,
    },
    !smashId
  );
}

export function usePlayerLegends(
  smashId: number | undefined,
  gameMode: number,
  isOfficial: boolean,
  year?: number
) {
  return useDBPostSWR<PlayerLegend>(
    `/player/legend`,
    {
      entrantSmashIds: [smashId],
      gameMode,
      isOfficial,
      year,
      maxResults: 5,
    },
    !smashId
  );
}

export function usePlayerStat(
  smashId: number | undefined,
  type:
    | "MOST_USED_SIG"
    | "AVERAGE_DAMAGE"
    | "MOST_PLAYED_WEAPON"
    | "AVERAGE_JUMPS"
    | "SET_WINS_PER_YEAR",
  extras: {
    isOfficial?: boolean;
    year?: number;
    gameMode?: number;
  }
) {
  return useDBPostSWR(
    `/stat`,
    {
      smashId,
      type,
      ...extras,
    },
    !smashId
  );
}

export function usePlayerPlacements(
  entrantSmashIds: (number | undefined)[],
  gameMode: number,
  isOfficial?: boolean
) {
  const endpoint = "/player/placement";

  const res = useDBPostInfiniteSWR<PlayerPlacement>((idx, prev) => {
    if (!entrantSmashIds.filter((x) => x).length) return null;
    const data: any = { entrantSmashIds, gameMode, isOfficial };

    if (idx === 0) return { endpoint, data };
    if (!prev.nextToken) return null;
    data.nextToken = prev.nextToken;
    return { endpoint, data };
  });

  const { setSize } = res;

  const serializedSmashIds = entrantSmashIds.join(",");
  // Jump back to page 1 whenever ids change
  useEffect(
    () => void setSize(1),
    [serializedSmashIds, gameMode, isOfficial, setSize]
  );
  return res;
}

export function usePlayerMatches(
  entrantSmashIds: (number | undefined)[],
  eventSlug: string | null
) {
  return useDBPostSWR<PlayerMatch>(
    "/player/match",
    {
      entrantSmashIds,
      eventSlug,
    },
    entrantSmashIds.some((x) => !x) || !eventSlug
  );
}

export function useMatchupScores(
  entrant1SmashIds: (number | undefined)[],
  entrant2SmashIds: (number | undefined)[] | null,
  gameMode: number,
  isOfficial?: boolean
) {
  return useDBPostSWR<MatchupScore>(
    "/matchup",
    {
      entrant1SmashIds,
      entrant2SmashIds,
      gameMode,
      isOfficial,
    },
    entrant1SmashIds.concat(entrant2SmashIds || []).some((x) => !x)
  );
}

export function useMatchupPlacements(
  entrant1SmashIds: (number | undefined)[],
  entrant2SmashIds: (number | undefined)[],
  gameMode: number,
  isOfficial?: boolean
) {
  const endpoint = "/matchup/placement";

  const res = useDBPostInfiniteSWR<MatchupPlacement>((idx, prev) => {
    if (!entrant1SmashIds.concat(entrant2SmashIds).filter((x) => x).length)
      return null;

    const data: any = {
      entrant1SmashIds,
      entrant2SmashIds,
      gameMode,
      isOfficial,
    };

    if (idx === 0) return { endpoint, data };
    if (!prev.nextToken) return null;
    data.nextToken = prev.nextToken;
    return { endpoint, data };
  });

  const { setSize } = res;

  const serializedSmashIds = entrant1SmashIds
    .concat(entrant2SmashIds)
    .join(",");
  // Jump back to page 1 whenever ids change
  useEffect(
    () => void setSize(1),
    [serializedSmashIds, gameMode, isOfficial, setSize]
  );

  return res;
}

export function useMatchupMatches(
  entrant1SmashIds: (number | undefined)[],
  entrant2SmashIds: (number | undefined)[],
  eventSlug: string | null
) {
  return useDBPostSWR<MatchupMatch>(
    "/matchup/match",
    { entrant1SmashIds, entrant2SmashIds, eventSlug },
    entrant1SmashIds.concat(entrant2SmashIds).some((x) => !x) || !eventSlug
  );
}
