import styles from "./style.module.scss";

export default function AuthLoading() {
  return (
    <div className={styles.loading}>
      <div className={styles.logo}>
        <img src="/logo.png" alt="Brawlhalla Stats" />
        <h1>
          <span>Brawlhalla</span> Pro Stats
        </h1>
      </div>
    </div>
  );
}
