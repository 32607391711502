import classNames from "classnames";
import React from "react";
import { ButtonHTMLAttributes } from "react";
import styles from "./style.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, icon, ...props }, ref) => (
    <button
      ref={ref}
      className={classNames(className, styles.button, {
        [styles.icon]: icon,
      })}
      {...props}
    />
  )
);
Button.displayName = "Button";

export default Button;
