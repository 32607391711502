export const OFFICIAL_LEAGUE_ID = 410531; // Brawlhalla Esports Year Seven

export const LEGENDS = new Map<string, string>(require("./data/legends.json"));
export const STATIC = "https://static.brawltools.com";

export const STANCES = new Map([
  ["", ""],
  ["Base", "Base"],
  ["Spd", "Speed"],
  ["Str", "Strength"],
  ["Dex", "Dexterity"],
  ["Def", "Defense"],
]);
export function pStatic(p: string) {
  return STATIC + p;
}
export function resolve(p: string) {
  if (p.startsWith("img/")) return pStatic(p.slice(3));
  return p;
}
