import { useEffect, useState } from "react";
import { smashFetcher, SMASH_API, useSmashSWR } from "./api";

export function useSGGPlayer(playerId: number | undefined) {
  return useSmashSWR(
    `query ($playerId: ID!) {
      player(id: $playerId) {
        id
        gamerTag
        prefix
        user {
          discriminator

          location {
            country
          }
          authorizations(types: [TWITCH, TWITTER]) {
            type
            externalUsername
            url
          }
        }
      }
    }`,
    { playerId },
    !playerId
  );
}

export function useSGGEvent(slug: string | undefined) {
  return useSmashSWR(
    `query ($slug: String!)  {
      event(slug: $slug) {
        name
        tournament {
          name
          events {
            name
            slug
            teamRosterSize {
              minPlayers
            }
          }
        }
        teamRosterSize {
          minPlayers
        }
        phases {
          id
          name
          phaseOrder
        }
      }
    }`,
    { slug },
    !slug
  );
}

export function useSGGSeeds(id: number | undefined) {
  return useSmashSWR(
    `query ($id: ID!) {
      phase(id: $id) {
        seeds(query: {
          sortBy:"seedNum ASC",
          perPage: 32
        }) {
          nodes {
            seedNum
            entrant{
              name
              participants {
                player {
                  id
                }
              }
            }
          }
        }
      }
    }`,
    { id },
    !id
  );
}

export function useSGGUpcomingEvents() {
  return useSmashSWR(
    `query {
      league(slug: "brawlhalla") {
        events(query: { perPage: 10,  filter: { upcoming: true } }) {
          nodes {
            teamRosterSize {
              minPlayers
            }
            slug
            name
            startAt
            tournament {
              name
            }
          }
        }
      }
    }`,
    {}
  );
}
