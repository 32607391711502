import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { pStatic } from "../../constants";

import { usePlayerMatches, usePlayerPlacements } from "../../hooks/data/db";
import useOnScreen from "../../hooks/use-on-screen";
import SmashGG from "../icons/smashgg";
import { didWin, formatScore, preprocessLegends } from "./helpers";
import styles from "./style.module.scss";

export default function PlayerPlacements({
  entrantSmashIds,
  gameMode,
  isOfficial,
  flipped,
  getOpponentURL,
}: {
  entrantSmashIds: (number | undefined)[];
  gameMode: number;
  isOfficial?: boolean;
  flipped?: boolean;
  getOpponentURL: (opponent: number[]) => string;
}) {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(ref);
  const [eventSlug, setEventSlug] = useState<string | null>(null);

  const ids = entrantSmashIds.join(",");
  useEffect(() => setEventSlug(null), [ids]);

  const {
    setSize,
    data: placements,
    isValidating,
  } = usePlayerPlacements(entrantSmashIds, gameMode, isOfficial);

  const { data: matches, isValidating: isLoadingMatches } = usePlayerMatches(
    entrantSmashIds,
    eventSlug
  );

  useEffect(() => {
    if (isOnScreen) setSize((size) => size + 1);
  }, [isOnScreen, setSize, placements?.length]);

  const events =
    placements
      ?.map((d) => d.playerPlacements)
      .filter((x) => x)
      .flat() || [];
  return (
    <section
      className={classNames(styles.placements, { [styles.flipped]: flipped })}
    >
      <table className={styles.placementTable}>
        <tbody>
          {events.map((evt) => {
            const active = eventSlug === evt.tournament.slug;
            return (
              <React.Fragment key={evt.tournament.slug}>
                <tr
                  onClick={() => {
                    setEventSlug(active ? null : evt.tournament.slug);
                  }}
                  className={classNames(styles.event, {
                    [styles.active]: active,
                  })}
                >
                  <td rowSpan={active ? 3 : 1} className={styles.placement}>
                    {evt.placement}
                  </td>
                  <td className={styles.name}>
                    <div className={styles.evt}>
                      {evt.tournament.tournamentName}
                    </div>
                    <div className={styles.tourney}>
                      {evt.tournament.eventName}
                    </div>
                  </td>
                  <td className={styles.date}>
                    {format(evt.tournament.startTime * 1000, "MM/dd/yyyy")}
                  </td>
                </tr>
                {active && (
                  <>
                    <tr className={styles.attributes}>
                      <td colSpan={2}>
                        <div>
                          <div>
                            {evt.tournament.isOfficial
                              ? "Official"
                              : "Community"}
                          </div>
                          <a
                            href={`https://smash.gg/${evt.tournament.slug}`}
                            target="_blank"
                            rel="noreferrer"
                            className="default"
                          >
                            <SmashGG />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr className={styles.matches}>
                      <td colSpan={2}>
                        <section
                          className={classNames(styles.container, {
                            [styles.noLegends]: gameMode !== 1,
                          })}
                        >
                          {matches?.playerMatches?.map((match) => {
                            const highlightClass = classNames({
                              [styles.highlight]: didWin(match.scores),
                            });
                            return (
                              <React.Fragment key={match.matchId}>
                                <div
                                  className={classNames(
                                    styles.legends,
                                    highlightClass
                                  )}
                                >
                                  {preprocessLegends(match.legends[0]).map(
                                    (x, i) => (
                                      <img
                                        key={i}
                                        src={pStatic(`/heads/${x}.png`)}
                                        alt={x}
                                        title={x}
                                        className={classNames({
                                          [styles.unknown]: x === "UNKNOWN",
                                        })}
                                      />
                                    )
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.score,
                                    highlightClass
                                  )}
                                  style={{
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {formatScore(match.scores[0])}
                                </div>
                                <div
                                  className={classNames(
                                    styles.divider,
                                    highlightClass
                                  )}
                                >
                                  —
                                </div>
                                <div
                                  className={classNames(
                                    styles.score,
                                    highlightClass
                                  )}
                                >
                                  {formatScore(match.scores[1])}
                                </div>
                                <div
                                  className={classNames(
                                    styles.legends,
                                    styles.flip,
                                    highlightClass
                                  )}
                                >
                                  {preprocessLegends(match.legends[1]).map(
                                    (x, i) => (
                                      <img
                                        key={i}
                                        src={pStatic(`/heads/${x}.png`)}
                                        alt={x}
                                        title={x}
                                        className={classNames({
                                          [styles.unknown]: x === "UNKNOWN",
                                        })}
                                      />
                                    )
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.opponent,
                                    highlightClass
                                  )}
                                >
                                  <Link
                                    to={{
                                      pathname: getOpponentURL(
                                        match.opponent.map((op) => op.smashId)
                                      ),
                                      search: location.search,
                                    }}
                                  >
                                    {match.opponent
                                      .map((op) => op.name)
                                      .join(" / ")}
                                  </Link>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </section>
                        {!isLoadingMatches &&
                          !matches?.playerMatches?.length && (
                            <div className={styles.noMatches}>
                              No matches found.
                            </div>
                          )}
                        {isLoadingMatches && (
                          <div
                            className={styles.noMatches}
                            style={{ opacity: 0.5 }}
                          >
                            Loading...
                          </div>
                        )}
                      </td>
                    </tr>
                  </>
                )}
              </React.Fragment>
            );
          })}
          {isValidating &&
            new Array(10).fill(null).map((_, i) => (
              <tr
                key={i}
                className={classNames(styles.preloader, styles.event)}
              >
                <td colSpan={3} />
              </tr>
            ))}
        </tbody>
      </table>
      <div ref={ref} />
    </section>
  );
}
