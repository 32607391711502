import { BestPostGame, DamageGraph, PostGame } from "../../types/stats";
import PlayerInfo from "./player-info";
import StatusBar from "./status-bar";
import styles from "./style.module.scss";
import TeamInfo from "./team-info";
import DamageGraphImage from "./damage-graph-image";

export function PostGameStats({
  post,
  best,
  damage,
  loading,
}: {
  post: PostGame | null;
  best: BestPostGame;
  damage: DamageGraph | null;
  loading: boolean;
}) {
  const isSingles = !post || post?.length === 2;
  const isDoubles = post?.length === 4;

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {new Array(Math.floor((post?.length || 0) / 2))
          .fill(null)
          .map((_, i) => (
            <PlayerInfo
              key={i}
              isSingles={isSingles}
              isDoubles={isDoubles}
              loading={loading}
              stat={post?.[i * 2] || null}
              best={best}
              i={i * 2}
            />
          ))}
      </div>
      <div className={styles.middle}>
        <StatusBar />
        <DamageGraphImage damage={damage} />
        {!isSingles && <TeamInfo best={best} data={post} />}
      </div>
      <div className={styles.right}>
        {new Array(Math.floor((post?.length || 0) / 2))
          .fill(null)
          .map((_, i) => (
            <PlayerInfo
              key={i}
              flipped
              isSingles={isSingles}
              isDoubles={isDoubles}
              loading={loading}
              stat={post?.[i * 2 + 1] || null}
              best={best}
              i={i * 2 + 1}
            />
          ))}
      </div>
    </div>
  );
}
